jQuery(document).foundation();

$('input').each(function() {
    $(this).on('focus', function() {
        $(this).parent().parent('.gfield').addClass('active');
    });
    $(this).on('blur', function() {
        if ($(this).val().length === 0) {
            $(this).parent().parent('.gfield').removeClass('active');
        }
    });
    if ($(this).val() != '') $(this).parent('.css').addClass('active');
});
// Gravity Form Complex Address Component
$('input').each(function() {
    $(this).on('focus', function() {
        $(this).parent('span').addClass('active');
    });
    $(this).on('blur', function() {
        if ($(this).val().length === 0) {
            $(this).parent('span').removeClass('active');
        }
    });
    if ($(this).val() != '') $(this).parent('.css').addClass('active');
});
//average everyday textareas
$('textarea').each(function() {
    $(this).on('focus', function() {
        $(this).parent().parent('.gfield').addClass('active');
    });
    $(this).on('blur', function() {
        if ($(this).val().length === 0) {
            $(this).parent().parent('.gfield').removeClass('active');
        }
    });
    if ($(this).val() != '') $(this).parent('.css').addClass('active');
});

$("select").change(function(){
    if($(this).val() !="")
    {
        // logic goes here
        $(this).parent('span').addClass('active');
        $(this).parent().parent('.gfield').addClass('active');
    } else {
        // no option is selected
        $(this).parent('span').removeClass('active');
        $(this).parent().parent('.gfield').removeClass('active');
    }
});